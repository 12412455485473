import axios from '../../axios.js'
import requestUrls from '../requestUrls'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {},
  
  actions: {   
    sendSms({
      commit
    }, payload) {
      const {phone, message} = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "sendSms")
        params.append("phone", phone)
        params.append("message", message)
        axios.post("https://tusbuddy.com/server/admin_server/sms_service.php", params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },
  },
}