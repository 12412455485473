import axios from '../../axios.js'
import requestUrls from '../requestUrls'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {

  },

  getters: {},

  mutations: {
    setTusFields(state, tusFields) {
      state.tusFields = tusFields;
    },
    setTrialExams(state, trialExams) {
      state.trialExams = trialExams;
    },

  },


  actions: {

    getTrialExams({
      commit
    }) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getTrialExams")
        axios.post(requestUrls.examsAdmin, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            commit('setTrialExams', response.data)
            resolve(response.data);
          }
        })
      })
    },

    getTrialExamUserResults({
      commit
    }, trialExamId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getTrialExamUserResults")
        params.append("trial_exam_id", trialExamId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          }
        })
      })
    },

    getUserTrialExamResultQuestions({ commit }, payload) {
      const { trialExamId, adminId } = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getUserTrialExamResultQuestions");
        params.append("user_id", adminId)
        params.append("trial_exam_id", trialExamId)
        axios
          .post(requestUrls.examsAdmin, params)
          .then(response => {
            if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
              resolve(response.data);
            } else {
              resolve([]);
            }
          })
          .catch(error => { reject(error) })
      })
    },


    getTusFields({
      commit
    }) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getTusFields")
        axios.post(requestUrls.examsAdmin, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            commit('setTusFields', response.data)
            resolve(response.data);
          } else {
            commit('setTusFields', [])
          }
        })
      })
    },


    getQuestionsOfExam({
      commit
    }, examId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getQuestionsOfExam")
        params.append("exam_id", examId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve([]);
          }
        })
      })
    },


    getPoolQuestions({ commit }) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getPoolQuestions")
        axios.post(requestUrls.examsAdmin, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },


    getExamQuestionsWithStatistics({
      commit
    }, examId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getExamQuestionsWithStatistics")
        params.append("exam_id", examId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(false);
          }
        })
      })
    },

    uploadImage({
      commit
    }, imageBase64) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "uploadImage")
        params.append("image_base_64", imageBase64)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          if (response && response.data && response.data.includes("http")) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
      })
    },

    deleteTrialExam({
      commit
    }, teId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "deleteTrialExam")
        params.append("te_id", teId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },


    addTrialExam({
      commit
    }, payload) {
      const {
        examName,
        questionCount,
        durationInMinutes,
        published,
        tus_analiz_exam,
        basicPartDescription,
        clinicalPartDescription,
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "addTrialExam")
        params.append("exam_name", examName)
        params.append("question_count", questionCount)
        params.append("duration_in_minutes", durationInMinutes)
        params.append("published", published)
        params.append("tus_analiz_exam", tus_analiz_exam)
        params.append("basic_part_description", basicPartDescription)
        params.append("clinical_part_description", clinicalPartDescription)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    updateTrialExam({
      commit
    }, payload) {
      const {
        teId,
        examName,
        questionCount,
        durationInMinutes,
        published,
        tus_analiz_exam,
        basicPartDescription,
        clinicalPartDescription,
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "updateTrialExam")
        params.append("te_id", teId)
        params.append("exam_name", examName)
        params.append("question_count", questionCount)
        params.append("duration_in_minutes", durationInMinutes)
        params.append("published", published)
        params.append("tus_analiz_exam", tus_analiz_exam)
        params.append("basic_part_description", basicPartDescription)
        params.append("clinical_part_description", clinicalPartDescription)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },


    deleteExamQuestion({
      commit
    }, questionId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "deleteExamQuestion")
        params.append("question_id", questionId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },


    deletePoolQuestion({
      commit
    }, questionId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "deletePoolQuestion")
        params.append("question_id", questionId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },


    addQuestionToExam({
      commit
    }, payload) {
      const {
        trialExamId,
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        titleId,
        subtitleId
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "addQuestionToExam")
        params.append("trial_exam_id", trialExamId)
        params.append("question_text", questionText)
        params.append("option_a", optionA)
        params.append("option_b", optionB)
        params.append("option_c", optionC)
        params.append("option_d", optionD)
        params.append("option_e", optionE)
        params.append("answer", answer)
        params.append("solution", solution)
        params.append("title_id", titleId)
        params.append("subtitle_id", subtitleId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    addQuestionToPool({
      commit
    }, payload) {
      const {
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        titleId,
        subtitleId
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "addQuestionToPool")
        params.append("question_text", questionText)
        params.append("option_a", optionA)
        params.append("option_b", optionB)
        params.append("option_c", optionC)
        params.append("option_d", optionD)
        params.append("option_e", optionE)
        params.append("answer", answer)
        params.append("solution", solution)
        params.append("title_id", titleId)
        params.append("subtitle_id", subtitleId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    transferQuestionToPool({ commit }, payload) {
      const {
        question_id,
        exam_id,
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "transferQuestionToPool")
        params.append("question_id", question_id)
        params.append("exam_id", exam_id)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    transferQuestionToTrialExamFromPool({ commit }, payload) {
      const {
        question_id,
        exam_id,
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "transferQuestionToTrialExamFromPool")
        params.append("question_id", question_id)
        params.append("exam_id", exam_id)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    updateExamQuestion({
      commit
    }, payload) {
      const {
        questionId,
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        titleId,
        subtitleId
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "updateExamQuestion")
        params.append("question_id", questionId)
        params.append("question_text", questionText)
        params.append("option_a", optionA)
        params.append("option_b", optionB)
        params.append("option_c", optionC)
        params.append("option_d", optionD)
        params.append("option_e", optionE)
        params.append("answer", answer)
        params.append("solution", solution)
        params.append("title_id", titleId)
        params.append("subtitle_id", subtitleId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },
    
    updatePoolQuestion({
      commit
    }, payload) {
      const {
        questionId,
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        titleId,
        subtitleId
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "updatePoolQuestion")
        params.append("question_id", questionId)
        params.append("question_text", questionText)
        params.append("option_a", optionA)
        params.append("option_b", optionB)
        params.append("option_c", optionC)
        params.append("option_d", optionD)
        params.append("option_e", optionE)
        params.append("answer", answer)
        params.append("solution", solution)
        params.append("title_id", titleId)
        params.append("subtitle_id", subtitleId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    cancelQuestion({ commit }, payload) {
      const { questionId, cancelDescription } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "cancelQuestion")
        params.append("question_id", questionId)
        params.append("cancel_description", cancelDescription)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    transferExamQuestions({ commit }, payload) {
      const { from_exam_name, to_exam_name } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "transferExamQuestions")
        params.append("from_exam_name", from_exam_name)
        params.append("to_exam_name", to_exam_name)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    removeQuestionCancel({ commit }, questionId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "removeQuestionCancel")
        params.append("question_id", questionId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    deleteExamParticipationOfUser({ commit }, payload) {
      const { adminId, trialExamId } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "deleteExamParticipationOfUser")
        params.append("user_id", adminId)
        params.append("trial_exam_id", trialExamId)
        axios.post(requestUrls.examsAdmin, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },


  },

}