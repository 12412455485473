import axios from '../../axios.js'
import requestUrls from '../requestUrls'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {

  },

  getters: {},

  mutations: {},

  actions: {

    getUsers({ commit }, payload) {
      const {
        nameFilter,
        surnameFilter,
        usernameFilter,
        emailFilter,
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getUsers")
        params.append("name_filter", nameFilter)
        params.append("surname_filter", surnameFilter)
        params.append("username_filter", usernameFilter)
        params.append("email_filter", emailFilter)
        axios.post(requestUrls.adminUsers, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },

    getTusAnalizUsers({ commit }) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getTusAnalizUsers")
        axios.post(requestUrls.adminUsers, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },

    getTrialExamResults({ commit }, adminId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getTrialExamResults")
        params.append("user_id", adminId)
        axios.post(requestUrls.adminUsers, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },

    getLessonExamResults({ commit }, adminId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getLessonExamResults")
        params.append("user_id", adminId)
        axios.post(requestUrls.adminUsers, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },


    addTusAnalizUser({ commit }, adminId) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "addTusAnalizUser")
        params.append("user_id", adminId)
        axios.post(requestUrls.adminUsers, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },

    removeTusAnalizUser({ commit }, adminId) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "removeTusAnalizUser")
        params.append("user_id", adminId)
        axios.post(requestUrls.adminUsers, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },

    removeLessonExamParticipation({ commit }, payload) {
      const { user_id, lesson_exam_id } = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "removeLessonExamParticipation")
        params.append("user_id", user_id)
        params.append("lesson_exam_id", lesson_exam_id)
        axios.post(requestUrls.adminUsers, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },

    removeTrialExamParticipation({ commit }, payload) {
      const { user_id, trial_exam_id } = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "removeTrialExamParticipation")
        params.append("user_id", user_id)
        params.append("trial_exam_id", trial_exam_id)
        axios.post(requestUrls.adminUsers, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },



  },

}