import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "/",
      component: () => import("@/views/admin/AdminOsymQuestions.vue"),
      meta: {
        pageTitle: "Ana Sayfa",
      },
    },
    {
      path: "/giris",
      name: "giris",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/sifremi-unuttum",
      name: "sifremi-unuttum",
      component: () => import("@/views/auth/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/erisim-kapali",
      name: "erisim-kapali",
      component: () => import("@/views/NoPermission.vue"),
      props: true,
    },

    {
      path: "/osym-sorulari",
      name: "osym-sorulari",
      component: () => import("@/views/admin/AdminOsymQuestions.vue"),
      meta: {
        pageTitle: "ÖSYM Soruları",
      },
    },
    {
      path: "/deneme-sinavlari",
      name: "deneme-sinavlari",
      component: () => import("@/views/admin/AdminExams.vue"),
      meta: {
        pageTitle: "TUS Denemeleri",
      },
    },
    {
      path: "/ders-denemeleri",
      name: "ders-denemeleri",
      component: () => import("@/views/admin/AdminLessonExams.vue"),
      meta: {
        pageTitle: "Ders Denemeleri",
      },
    },

    {
      path: "/soru-istatistikleri",
      name: "soru-istatistikleri",
      component: () => import("@/views/admin/AdminQuestionStatistics.vue"),
      meta: {
        pageTitle: "Soru İstatistikleri",
      },
    },

    {
      path: "/kullanicilar",
      name: "kullanicilar",
      component: () => import("@/views/admin/AdminUsers.vue"),
      meta: {
        pageTitle: "Kullanıcılar",
      },
    },

    {
      path: "/kadro-anketleri",
      name: "kadro-anketleri",
      component: () => import("@/views/admin/AdminCadreSurveys.vue"),
      meta: {
        pageTitle: "Kadro Anketleri",
      },
    },

    {
      path: "/coktan-secmeli-anketler",
      name: "coktan-secmeli-anketler",
      component: () => import("@/views/admin/AdminUserSurveys.vue"),
      meta: {
        pageTitle: "Ç. Seçmeli Anketler",
      },
    },

    {
      path: "/acik-uclu-anketler",
      name: "acik-uclu-anketler",
      component: () => import("@/views/admin/AdminOpenEndedSurveys.vue"),
      meta: {
        pageTitle: "Açık Uçlu Anketler",
      },
    },


    {
      path: "/sms-servisi",
      name: "sms-servisi",
      component: () => import("@/views/admin/AdminSms.vue"),
      meta: {
        pageTitle: "SMS Gönderimi",
      },
    },

    {
      path: "/app_stats",
      name: "app_stats",
      component: () => import("@/views/admin/AdminAppStatistics.vue"),
      meta: {
        pageTitle: "İstatistikler",
      },
    },

  ],
});

export default router;
