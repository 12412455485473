import axios from '../../axios.js'
import requestUrls from '../requestUrls.js'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {
  },

  getters: {},

  mutations: {
  },


  actions: {

    getStatistics({ commit }, functionName) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", functionName);
        axios
          .post("https://tusbuddy.com/server/admin_server/app_statistics.php", params)
          .then(response => {
            if (response && response.data) {
              resolve(response.data);
            } else {
              resolve([]);
            }
          })
          .catch(error => { reject(error) })
      })
    },

  },

}