// axios
import axios from 'axios'

const ajax = axios.create({

})
ajax.CancelToken = axios.CancelToken
ajax.isCancel = axios.isCancel
/*
* The interceptor here ensures that we check for the token in local storage every time an ajax request is made
*/
ajax.interceptors.request.use(
 config => {
  var adminObject = JSON.parse(localStorage.getItem("adminObject"))
   let token = localStorage.getItem('accessToken')
   if (token && adminObject) {
    config.headers['Token'] = token 
    config.data = "a_id="+ adminObject.a_id + "&" + config.data
  }
  return config
 },
 error => {
  return Promise.reject(error)
 }
)
export default ajax