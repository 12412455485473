
const adminBaseURL = "https://tusbuddy.com/server/admin_server"

export default {

  auth: adminBaseURL + "/admins.php",

  adminOpenEndedSurveys: adminBaseURL + "/open_ended_surveys.php",
  adminCadreSurveys: adminBaseURL + "/cadre_surveys.php",
  adminUserSurveys: adminBaseURL + "/user_surveys.php",
  examsAdmin: adminBaseURL + "/trial_exams.php",
  adminLessonExams: adminBaseURL + "/lesson_exams.php",
  adminUsers: adminBaseURL + "/users.php",
  adminQuestionStatistics: adminBaseURL + "/question_statistics.php",

  osymQuestions: adminBaseURL + "/osym_questions_test.php",

}
