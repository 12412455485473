import axios from "../../axios.js";
import requestUrls from "../requestUrls";
import router from "@/router";
import store from "@/store/index";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    },
  },
  actions: {
    getNewToken({ commit }) {
      var adminObject = JSON.parse(localStorage.getItem("adminObject"));
      if (adminObject) {
        var userId = adminObject["a_id"];
        // Token zaten axios'ta yükleniyor 
        const params = new URLSearchParams();
        params.append("func", "getNewToken");
        params.append("user_id", userId);
        return new Promise((resolve) => {
          axios.post(requestUrls.auth, params).then((response) => {
            // logout olmuşsa dışarı at
            if (response.data && response.data == "Not Authorized") {
              localStorage.removeItem("adminObject");
              localStorage.removeItem("accessToken");
              router.push("/login").catch(() => {});
            }
            if (response && response.data && response.data.length > 30) {
              localStorage.setItem("accessToken", response.data);
              resolve(true);
            } else {
              router.push("/login").catch(() => {});
            }
          });
        });
      }
    },

    sendLoginCode({ commit }, payload) {
      let phone = payload.phone;
      phone = phone == null ? "" : phone.length == 13 ? "0" + phone : phone;
      let password = payload.password;
      return new Promise((resolve, reject) => {
        phone = phone == null ? "" : phone.length == 13 ? "0" + phone : phone;
        const params = new URLSearchParams();
        params.append("func", "sendLoginCode");
        params.append("phone", phone);
        params.append("password", password);
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            resolve(response.data == "TRUE");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //
    login({ commit }, payload) {
      let phone = payload.phone;
      phone = phone == null ? "" : phone.length == 13 ? "0" + phone : phone;
      let loginCode = payload.loginCode;

      const params = new URLSearchParams();
      params.append("func", "getUserByLoginCode");
      params.append("phone", phone);
      params.append("login_code", loginCode);
      return new Promise((resolve) => {
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            try {
              if (
                response.data != null &&
                typeof response.data[0] === "object"
              ) {
                var user = response.data[0];
                if (user.phone_verified == "0") {
                  resolve("phone_not_verified");
                  return;
                }
                var token = user["token"];
                if (!router.currentRoute.query.to) {
                  router.currentRoute.query.to = "/";
                }
                // Navigate User to homepage
                router.push(router.currentRoute.query.to || "/");
                // Set accessToken
                localStorage.setItem("accessToken", token);

                // token'ı user'dan kaldır
                user.token = null;

                store.adminObject = user;
                // local'e de kaydet
                localStorage.setItem("adminObject", JSON.stringify(user));

                // Set bearer token in axios
                commit("SET_BEARER", token);
                resolve("login_successful");
                return;
              }
            } catch (err) {}
            payload.showError("Girdiğiniz kod eşleşmiyor!");

            resolve("wrong_password");
          })
          .catch((error) => {
            payload.showError("Beklenmedik bir hata oluştu!");
          });
      });
    },

    getUserByPhoneNumber({ commit }, phone) {
      phone = phone == null ? "" : phone.length == 13 ? "0" + phone : phone;
      const params = new URLSearchParams();
      params.append("func", "getUserByPhoneNumber");
      params.append("phone", phone);
      return new Promise((resolve) => {
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            try {
              if (
                response.data != null &&
                typeof response.data[0] === "object"
              ) {
                var user = response.data[0];
                if (user.phone_verified == "0") {
                  resolve("phone_not_verified");
                  return;
                }
                var token = user["token"];
                if (!router.currentRoute.query.to) {
                  router.currentRoute.query.to = "/";
                }
                // Navigate User to homepage
                router.push(router.currentRoute.query.to || "/");
                // Set accessToken
                localStorage.setItem("accessToken", token);

                // token'ı user'dan kaldır
                user.token = null;

                store.adminObject = user;
                // local'e de kaydet
                localStorage.setItem("adminObject", JSON.stringify(user));

                // Set bearer token in axios
                commit("SET_BEARER", token);
                resolve(true);
                return;
              }
            } catch (err) {}
            resolve(false);
          })
          .catch((error) => {
            commit(); // commit kullanılmadı hatası için
            resolve(error);
          });
      });
    },

    sendPhoneVerificationMessage({ commit }, phone) {
      return new Promise((resolve, reject) => {
        phone = phone == null ? "" : phone.length == 13 ? "0" + phone : phone;
        const params = new URLSearchParams();
        params.append("func", "sendPhoneVerificationMessage");
        params.append("phone", phone);
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            resolve(response.data == "TRUE");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getRequestInfo({ commit }, smsCode) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getRequestInfo");
        params.append("sms_code", smsCode);
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            try {
              if (
                response.data != null &&
                typeof response.data[0] === "object"
              ) {
                resolve(response.data[0]);
                return;
              }
            } catch (err) {}
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    isPhoneAvailable({ commit }, phone) {
      phone = phone == null ? "" : phone.length == 13 ? "0" + phone : phone;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "isPhoneAvailable");
        params.append("phone", phone);
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            resolve(response.data == "TRUE");
          })
          .catch((error) => {
            commit(); // commit kullanılmadı hatası için
            reject(error);
          });
      });
    },

    verifyPhoneNumber({ commit }, smsCode) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "verifyPhoneNumber");
        params.append("sms_code", smsCode);
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            resolve(response.data == "TRUE");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    isUsernameAvailable({ commit }, username) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "isUserNameAvailable");
        params.append("user_name", username);
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            resolve(response.data == "TRUE");
          })
          .catch((error) => {
            commit(); // commit kullanılmadı hatası için
            reject(error);
          });
      });
    },

    sendResetPasswordCodeSMS({ commit }, phone) {
      phone = phone == null ? "" : phone.length == 13 ? "0" + phone : phone;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "sendResetPasswordCodeSMS");
        params.append("phone", phone);
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            resolve(response.data == "TRUE");
          })
          .catch((error) => {
            commit(); // commit kullanılmadı hatası için
            reject(error);
          });
      });
    },

    resetPassword({ commit }, payload) {
      var { phone, password, verificationCode } = payload;
      phone = phone == null ? "" : phone.length == 13 ? "0" + phone : phone;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "resetPassword");
        params.append("phone", phone);
        params.append("password", password);
        params.append("verification_code", verificationCode);
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            if (response && response.data) {
              var result = response.data;
              if (result.startsWith("TOKEN:")) {
                var token = response.data.substring(6, response.data.length);
                localStorage.setItem("accessToken", token);
                resolve(true);
              } else {
                resolve(result == "TRUE");
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            commit(); // commit kullanılmadı hatası için
            reject(error);
          });
      });
    },

    getUniversityList({ commit }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getUniversityList");
        axios
          .post(requestUrls.auth, params)
          .then((response) => {
            if (
              response.data != null &&
              Array.isArray(response.data) &&
              typeof response.data[0] === "object"
            ) {
              resolve(response.data);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            reject(error);
            commit(); // commit kullanılmadı hatası için
          });
      });
    },
  },
};
