import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './auth/authModule'

import adminOpenEndedSurveys from './admin/adminOpenEndedSurveysModule'
import adminCadreSurveys from './admin/adminCadreSurveysModule'
import adminUserSurveys from './admin/adminUserSurveysModule'
import examsAdmin from './admin/adminExamsModule'
import adminLessonExams from './admin/adminLessonExamsModule'
import adminUsers from './admin/adminUsersModule'
import adminQuestionStatistics from './admin/adminQuestionStatisticsModule'
import adminSms from './admin/adminSmsModule'

import adminOsymQuestions from './admin/adminOsymQuestionsModule'

import appStatistics from './admin/adminAppStatisticsModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    examsAdmin,
    adminLessonExams,
    adminUsers,

    appStatistics,

    adminOpenEndedSurveys,
    adminCadreSurveys,
    adminUserSurveys,
    adminQuestionStatistics,
    adminSms,

    adminOsymQuestions,
  },
  strict: process.env.DEV,
})
