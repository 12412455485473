import axios from '../../axios.js'
import requestUrls from '../requestUrls'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
    setSurveyList(state, surveyList) {
      surveyList.forEach(survey => {
        survey["user_answers"] = JSON.parse(survey.survey_json);
        survey["survey_json"] = null;
      });
      state.surveyList = surveyList;
    },
    setCityInstitutionList(state, cityInstitutionList) {
      state.cityInstitutionList = cityInstitutionList
    },
  },
  
  actions: {   

    fetchSurveys({ commit }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getSurveys");
        axios
          .post(requestUrls.adminCadreSurveys, params)
          .then(response => {
            if (response.data != null && typeof response.data[0] === 'object') {
              var surveys = response.data;
              surveys.sort(function (s1, s2) {
                return s2.creation_time.localeCompare(s1.creation_time);
              });
              commit('setSurveyList', surveys);
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
            commit(); // commit kullanılmadı hatası için
          })
      })
    },

    fetchSurvey({ commit }, surveyId) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getSurvey");
        params.append("survey_id", surveyId);        
        axios
          .post(requestUrls.adminCadreSurveys, params)
          .then(response => {
            if (response.data != null && typeof response.data[0] === 'object') {
              var survey = response.data[0];
              survey["user_answers"] = JSON.parse(survey.survey_json);
              resolve(survey)
            }
          })
          .catch(error => {
            reject(error)
            commit(); // commit kullanılmadı hatası için
          })
      })
    },    

    fetchCityInstitutionList({ commit }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getCityInstitutionList");
        axios
          .post(requestUrls.adminCadreSurveys, params)
          .then(response => {
            commit('setCityInstitutionList', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
            commit(); // commit kullanılmadı hatası için
          })
      })
    },
    
    approveSurvey({
      commit
    }, payload) {
      const {
        survey_id,
        full_name,        
      } = payload
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "approveSurvey")
        params.append("survey_id", survey_id)
        params.append("full_name", full_name)
        axios.post(requestUrls.adminCadreSurveys, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },

    deleteSurvey({
      commit
    }, surveyId) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "deleteSurvey")
        params.append("survey_id", surveyId)
        axios.post(requestUrls.adminCadreSurveys, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },

    updateGiftCode({
      commit
    }, payload) {
      const {survey_id, gift_code} = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "updateGiftCode")
        params.append("survey_id", survey_id)
        params.append("gift_code", gift_code)
        axios.post(requestUrls.adminCadreSurveys, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },

    sendSms({
      commit
    }, payload) {
      const {phone, message} = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "sendSms")
        params.append("phone", phone)
        params.append("message", message)
        axios.post(requestUrls.adminCadreSurveys, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },

    sendEmail({
      commit
    }, payload) {
      const {email, message} = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append("func", "sendEmail")
        params.append("email", email)
        params.append("message", message)
        axios.post(requestUrls.adminCadreSurveys, params).then(response => {
          resolve(response.data == "TRUE")
        }).catch(error => {
          commit() // commit kullanılmadı hatası için
          reject(error)
        })
      })
    },

  },
}