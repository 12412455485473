import axios from '../../axios.js'
import requestUrls from '../requestUrls'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {},

  actions: {

    getTrialExamQuestionStatistics({ commit }, userCategory) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getTrialExamQuestionStatistics");
        params.append("user_category", userCategory);
        axios
          .post(requestUrls.adminQuestionStatistics, params)
          .then(response => {
            if (response.data != null && typeof response.data[0] === 'object') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
            commit(); // commit kullanılmadı hatası için
          })
      })
    },

    getLessonExamQuestionStatistics({ commit }, userCategory) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getLessonExamQuestionStatistics");
        params.append("user_category", userCategory);
        axios
          .post(requestUrls.adminQuestionStatistics, params)
          .then(response => {
            if (response.data != null && typeof response.data[0] === 'object') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
            commit(); // commit kullanılmadı hatası için
          })
      })
    },

    loadTrialExamQuestionUserAnswerDistribution({ commit }, payload) {
      const {question, user_category} = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getTrialExamQuestionUserAnswerDistribution");
        params.append("question_id", question.question_id)
        params.append("trial_exam_id", question.trial_exam_id)
        params.append("user_category", user_category);
        axios
          .post(requestUrls.adminQuestionStatistics, params)
          .then(response => {
            if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
              var result = response.data[0];
              question.a_count = result["a_count"];
              question.b_count = result["b_count"];
              question.c_count = result["c_count"];
              question.d_count = result["d_count"];
              question.e_count = result["e_count"];
              question.participant_count = result["participant_count"];
              question.distributionLoaded = true;
            }
            resolve(question);
          })
          .catch(error => { reject(error) })
      })
    },

    loadLessonExamQuestionUserAnswerDistribution({ commit }, payload) {
      const {question, user_category} = payload;
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getLessonExamQuestionUserAnswerDistribution");
        params.append("question_id", question.question_id)
        params.append("lesson_exam_id", question.lesson_exam_id)
        params.append("user_category", user_category);
        axios
          .post(requestUrls.adminQuestionStatistics, params)
          .then(response => {
            if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
              var result = response.data[0];
              question.a_count = result["a_count"];
              question.b_count = result["b_count"];
              question.c_count = result["c_count"];
              question.d_count = result["d_count"];
              question.e_count = result["e_count"];
              question.participant_count = result["participant_count"];
              question.distributionLoaded = true;
            }
            resolve(question);
          })
          .catch(error => { reject(error) })
      })
    },

  },
}