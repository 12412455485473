import axios from "../../axios.js";
import requestUrls from "../requestUrls.js";
import router from "@/router";
import store from "@/store";

export default {
  namespaced: true,
  state: {},

  getters: {},

  mutations: {},

  actions: {
    getOsymExams({ commit }) {
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "getOsymExams");
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          if (
            response &&
            response.data &&
            Array.isArray(response.data) &&
            typeof response.data[0] === "object"
          ) {
            resolve(response.data);
          } else {
            resolve([]);
          }
        });
      });
    },

    getCourseSubjects({ commit }) {
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "getCourseSubjects");
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          if (
            response &&
            response.data &&
            Array.isArray(response.data) &&
            typeof response.data[0] === "object"
          ) {
            resolve(response.data);
          } else {
            resolve([]);
          }
        });
      });
    },

    getQuestionsOfExam({ commit }, payload) {
      const { year, tusNumber, fetchAlternatives } = payload;
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "getQuestionsOfExam");
        params.append("year", year);
        params.append("tus_number", tusNumber);
        params.append("fetch_alternatives", fetchAlternatives);
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          if (
            response &&
            response.data &&
            Array.isArray(response.data) &&
            typeof response.data[0] === "object"
          ) {
            resolve(response.data);
          } else {
            resolve([]);
          }
        });
      });
    },

    uploadImage({ commit }, imageBase64) {
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "uploadImage");
        params.append("image_base_64", imageBase64);
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          if (response && response.data && response.data.includes("http")) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        });
      });
    },

    deleteQuestion({ commit }, questionId) {
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "deleteQuestion");
        params.append("question_id", questionId);
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          resolve(response && response.data && response.data == "TRUE");
        });
      });
    },


    isQuestionAvaiable({ commit }, payload) {
      const { year, tusNumber, questionOrder } = payload;
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "isQuestionAvaiable");
        params.append("year", year);
        params.append("tus_number", tusNumber);
        params.append("question_order", questionOrder);
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          resolve(response && response.data && response.data == "TRUE");
        });
      });
    },

    addQuestion({ commit }, payload) {
      const {
        year,
        tusNumber,
        questionOrder,
        courseName,
        subjectName,
        isClinical,
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        tags,
      } = payload;
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "addQuestion");
        params.append("year", year);
        params.append("tus_number", tusNumber);
        params.append("question_order", questionOrder);
        params.append("course_name", courseName);
        params.append("subject_name", subjectName);
        params.append("is_clinical", isClinical);
        params.append("question_text", questionText);
        params.append("option_a", optionA);
        params.append("option_b", optionB);
        params.append("option_c", optionC);
        params.append("option_d", optionD);
        params.append("option_e", optionE);
        params.append("answer", answer);
        params.append("solution", solution);
        params.append("tags", tags);
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          resolve(response && response.data && response.data == "TRUE");
        });
      });
    },

    updateQuestion({ commit }, payload) {
      const {
        year,
        tusNumber,
        questionOrder,
        courseName,
        subjectName,
        isClinical,
        questionId,
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        tags,
        isApply,
      } = payload;

      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", isApply ? "applyQuestion" : "updateQuestion");
        params.append("year", year);
        params.append("tus_number", tusNumber);
        params.append("question_order", questionOrder);
        params.append("course_name", courseName);
        params.append("subject_name", subjectName);
        params.append("is_clinical", isClinical);
        params.append("question_id", questionId);
        params.append("question_text", questionText);
        params.append("option_a", optionA);
        params.append("option_b", optionB);
        params.append("option_c", optionC);
        params.append("option_d", optionD);
        params.append("option_e", optionE);
        params.append("answer", answer);
        params.append("solution", solution);
        params.append("tags", tags);
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          resolve(response && response.data && response.data == "TRUE");
        });
      });
    },

    cancelQuestion({ commit }, payload) {
      const { questionId, cancelDescription } = payload;
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "cancelQuestion");
        params.append("question_id", questionId);
        params.append("cancel_description", cancelDescription);
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          resolve(response && response.data && response.data == "TRUE");
        });
      });
    },

    removeQuestionCancel({ commit }, questionId) {
      return new Promise((resolve) => {
        const params = new URLSearchParams();
        params.append("func", "removeQuestionCancel");
        params.append("question_id", questionId);
        axios.post(requestUrls.osymQuestions, params).then((response) => {
          resolve(response && response.data && response.data == "TRUE");
        });
      });
    },

  },
};
