import axios from '../../axios.js'
import requestUrls from '../requestUrls'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {

  },

  getters: {},

  mutations: {},

  actions: {

    getLessonExamUserResults({
      commit
    }, lessonExamId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getLessonExamUserResults")
        params.append("lesson_exam_id", lessonExamId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          }
        })
      })
    },

    getLessonExamQuestionsWithStatistics({
      commit
    }, examId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getLessonExamQuestionsWithStatistics")
        params.append("exam_id", examId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve([]);
          }
        })
      })
    },

    addLessonExam({ commit }, payload) {
      const {
        title_id,
        exam_order,
        question_count,
        duration_in_minutes
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "addLessonExam")
        params.append("title_id", title_id)
        params.append("exam_order", exam_order)
        params.append("question_count", question_count)
        params.append("duration_in_minutes", duration_in_minutes)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    updateLessonExam({ commit }, payload) {
      const {
        lesson_exam_id,
        title_id,
        exam_order,
        question_count,
        duration_in_minutes,
        published,
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "updateLessonExam")
        params.append("lesson_exam_id", lesson_exam_id)
        params.append("title_id", title_id)
        params.append("exam_order", exam_order)
        params.append("question_count", question_count)
        params.append("duration_in_minutes", duration_in_minutes)
        params.append("published", published)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    deleteLessonExam({ commit }, lessonExamId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "deleteLessonExam")
        params.append("lesson_exam_id", lessonExamId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    getLessonExams({ commit }) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getLessonExams")
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },

    getTusFields({ commit }) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getTusFields")
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },

    getQuestionsOfLessonExam({ commit }, lessonExamId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getQuestionsOfLessonExam")
        params.append("lesson_exam_id", lessonExamId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },

    getPoolQuestions({ commit }) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "getPoolQuestions")
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          if (response && response.data && Array.isArray(response.data) && typeof response.data[0] === "object") {
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        })
      })
    },

    transferQuestionToLessonExamFromPool({ commit }, payload) {
      const {
        question_id,
        lesson_exam_id,
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "transferQuestionToLessonExamFromPool")
        params.append("question_id", question_id)
        params.append("lesson_exam_id", lesson_exam_id)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },
    
    transferQuestionToPool({ commit }, payload) {
      const {
        question_id,
        lesson_exam_id,
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "transferQuestionToPool")
        params.append("question_id", question_id)
        params.append("lesson_exam_id", lesson_exam_id)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    addQuestionToExam({ commit }, payload) {
      const {
        questionText,
        lessonExamId,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        titleId,
        subtitleId
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "addQuestionToExam")
        params.append("question_text", questionText)
        params.append("lesson_exam_id", lessonExamId)        
        params.append("option_a", optionA)
        params.append("option_b", optionB)
        params.append("option_c", optionC)
        params.append("option_d", optionD)
        params.append("option_e", optionE)
        params.append("answer", answer)
        params.append("solution", solution)
        params.append("title_id", titleId)
        params.append("subtitle_id", subtitleId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    addQuestionToPool({ commit }, payload) {
      const {
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        titleId,
        subtitleId
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "addQuestionToPool")
        params.append("question_text", questionText)
        params.append("option_a", optionA)
        params.append("option_b", optionB)
        params.append("option_c", optionC)
        params.append("option_d", optionD)
        params.append("option_e", optionE)
        params.append("answer", answer)
        params.append("solution", solution)
        params.append("title_id", titleId)
        params.append("subtitle_id", subtitleId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    updateExamQuestion({
      commit
    }, payload) {
      const {
        questionId,
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        titleId,
        subtitleId
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "updateExamQuestion")
        params.append("question_id", questionId)
        params.append("question_text", questionText)
        params.append("option_a", optionA)
        params.append("option_b", optionB)
        params.append("option_c", optionC)
        params.append("option_d", optionD)
        params.append("option_e", optionE)
        params.append("answer", answer)
        params.append("solution", solution)
        params.append("title_id", titleId)
        params.append("subtitle_id", subtitleId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    updatePoolQuestion({
      commit
    }, payload) {
      const {
        questionId,
        questionText,
        optionA,
        optionB,
        optionC,
        optionD,
        optionE,
        answer,
        solution,
        titleId,
        subtitleId
      } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "updatePoolQuestion")
        params.append("question_id", questionId)
        params.append("question_text", questionText)
        params.append("option_a", optionA)
        params.append("option_b", optionB)
        params.append("option_c", optionC)
        params.append("option_d", optionD)
        params.append("option_e", optionE)
        params.append("answer", answer)
        params.append("solution", solution)
        params.append("title_id", titleId)
        params.append("subtitle_id", subtitleId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    uploadImage({
      commit
    }, imageBase64) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "uploadImage")
        params.append("image_base_64", imageBase64)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          if (response && response.data && response.data.includes("http")) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
      })
    },

    deleteLessonExamQuestion({
      commit
    }, questionId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "deleteLessonExamQuestion")
        params.append("question_id", questionId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    deletePoolQuestion({
      commit
    }, questionId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "deletePoolQuestion")
        params.append("question_id", questionId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    cancelQuestion({commit}, payload) {
      const {questionId, cancelDescription} = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "cancelQuestion")
        params.append("question_id", questionId)
        params.append("cancel_description", cancelDescription)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

    removeQuestionCancel({commit}, questionId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "removeQuestionCancel")
        params.append("question_id", questionId)
        axios.post(requestUrls.adminLessonExams, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },


  },

}