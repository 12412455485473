import axios from '../../axios.js'
import requestUrls from '../requestUrls.js'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
  },

  actions: {

    getQuestionTopics({ commit }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getQuestionTopics");
        axios
          .post(requestUrls.adminOpenEndedSurveys, params)
          .then(response => {
            if (response.data != null && typeof response.data[0] === 'object') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
            commit(); // commit kullanılmadı hatası için
          })
      })
    },


    getAnswersOfTopic({ commit }, topic) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getAnswersOfTopic");
        params.append("topic", topic);
        axios
          .post(requestUrls.adminOpenEndedSurveys, params)
          .then(response => {
            if (response.data != null && typeof response.data[0] === 'object') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
            commit(); // commit kullanılmadı hatası için
          })
      })
    },

    
    deleteUserAnswer({ commit }, questionId) {
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "deleteUserAnswer")
        params.append("nq_id", questionId)
        axios.post(requestUrls.adminOpenEndedSurveys, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },


    updateUserAnswer({ commit }, payload) {
      const { nq_id, importance, reviewed } = payload;
      return new Promise(resolve => {
        const params = new URLSearchParams()
        params.append("func", "updateUserAnswer")
        params.append("nq_id", nq_id)
        params.append("importance", importance)
        params.append("reviewed", reviewed)
        axios.post(requestUrls.adminOpenEndedSurveys, params).then(response => {
          resolve(response && response.data && response.data == "TRUE");
        })
      })
    },

  },
}