import axios from '../../axios.js'
import requestUrls from '../requestUrls'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
  },

  actions: {

    getSurveys({ commit }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append("func", "getSurveys");
        axios
          .post(requestUrls.adminUserSurveys, params)
          .then(response => {
            var surveyList = [];
            if (response.data != null && typeof response.data[0] === 'object') {
              var surveys = response.data;
              surveys.forEach(survey => {
                var added = false;
                for (var i = 0; i <  surveyList.length; i++) {
                  if (surveyList[i].survey_name == survey.survey_name) {
                    surveyList[i]["result_" + survey.user_answer] = survey.selection_count;
                    added = true;
                    break;
                  }
                }
                if (added == false) {
                  survey["result_" + survey.user_answer] = survey.selection_count;
                  surveyList.push(survey);
                }
              });
              surveyList.sort(function (s1, s2) {
                return s2.date_time.localeCompare(s1.date_time);
              });
              surveyList.forEach(survey => {
                var userAnswerCount = 0;
                for(var i = 1; i<= 10; i++){
                  var result = survey["result_" + i];
                  if(result != null && result > 0){
                    userAnswerCount += result;
                  }
                }
                survey["user_answer_count"] = userAnswerCount;
              });
              resolve(surveyList)
            }
          })
          .catch(error => {
            reject(error)
            commit(); // commit kullanılmadı hatası için
          })
      })
    },

  },
}